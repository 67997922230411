.backdrop{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: 10;
	background: rgba(0, 0, 0, 0.75);
}

.modal{
	background-color: #fff;
	position: fixed;
	top: 30vh;
	left: 10%;
	width: 80%;
	z-index: 100;
	overflow: hidden;
}

.header{
	background-color: #fff;
	padding: 1rem;
}

.header h2{
	margin: 0;
	color: #f34500;
}

.content{
	padding: 1rem;
	min-height: 7rem;
}

.actions{
	background-color: #fff;
	padding: 1rem;
	display: flex;
	justify-content: flex-end;
}
/* .actions .button-wrap{
	display: flex;
	flex-direction: column-reverse;		
} */
/* .button-wrap button{
	margin-left: 20px;
} */
.actions .button_wrap{
	width: 100%;
}
.actions .button_wrap button{
	margin-top: 20px;
	width: 100%;
}
	
@media all and (min-width: 48em){
	.actions .button_wrap{
		text-align: right;
	}
	
	.actions .button_wrap button{
		margin-left: 20px;
		width: auto;
	}
}

@media (min-width: 48em){
	.modal{
	  left: calc(50% - 20rem);
	  width: 40rem;
	}
}